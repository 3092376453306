import React from "react";
import styled from "styled-components";
import { device } from "./../Helper/device";
import Header from "../components/header";
import Footer from "../components/footer";

const Title = styled.div`
  font-size: 30px;
  text-align: center;
  @media ${device.tablet} {
    font-size: 32px;
  }
  @media ${device.desktop} {
    font-size: 34px;
  }
`;

const ContentWrap = styled.section`
  margin: 40px 20px 20px;
  @media ${device.tablet} {
    margin: 40px 40px;
  }
  @media ${device.desktop} {
    max-width: 1080px;
    margin: 40px auto;
  }
`;

const Contents = styled.div`
  display: block;
  width: 100%;
  @media ${device.tablet} {
    min-height: 80px;
  }
`;

const ContentsTitle = styled.div`
  font-size: 14px;
  line-height: 1.9;
  text-align: left;
  color: #333333;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin: 10px 0;
  padding: 2px;
  padding-left: 8px;
  background-color: #f7f7f7;
  @media ${device.tablet} {
    font-size: 16px;
  }
  @media ${device.desktop} {
    font-size: 18px;
  }
`;

const Details = styled.div`
  display: flex;
  width: 100%;
  font-size: 14px;
  color: #333333;
  align-items: center;
  margin-bottom: 10px;
  @media ${device.tablet} {
    font-size: 16px;
  }
  @media ${device.desktop} {
    font-size: 18px;
  }
`;

const CompanyOverviewPage = () => {
  return (
    <>
      <Header />
      <ContentWrap>
        <Title>会社概要</Title>
        <Contents>
          <ContentsTitle>会社名</ContentsTitle>
          <Details>株式会社 八坂堂</Details>
        </Contents>
        <Contents>
          <ContentsTitle>創業</ContentsTitle>
          <Details>令和04年01月04日</Details>
        </Contents>
        <Contents>
          <ContentsTitle>所在地</ContentsTitle>
          <Details>福岡県宗像市ひかりが丘6丁目2番地36</Details>
        </Contents>
        <Contents>
          <ContentsTitle>資本金</ContentsTitle>
          <Details>100万円</Details>
        </Contents>
        <Contents>
          <ContentsTitle>代表取締役</ContentsTitle>
          <Details>八坂 直人</Details>
        </Contents>
        <Contents>
          <ContentsTitle>業務内容</ContentsTitle>
          <Details>Webサイトの企画・制作・運営</Details>
          <Details>Webサイトのコンサルティング</Details>
          <Details>メディアの企画・制作・運営</Details>
          <Details>マーケティングコンサルティング</Details>
          <Details>ブランドコンサルティング</Details>
        </Contents>
      </ContentWrap>
      <Footer />
    </>
  );
};

export default CompanyOverviewPage;
